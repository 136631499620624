import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { BsCaretLeftFill, BsFillHeartFill } from "react-icons/bs";

const ThankYouStyles = styled.main`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, var(--column-width)));
  gap: 0 2rem;
  margin: 0;
  height: 100%;

  grid-row: 1 / span 2;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  .heart {
    width: 10rem;
    height: 10rem;
    margin-bottom: 2rem;
  }

  .btn {
    svg {
      margin-bottom: -0.15em;
    }
  }
`;

const ThankYou = () => {
  return (
    <Layout>
      <SEO title="Thank You" />
      <ThankYouStyles>
        <BsFillHeartFill className="heart" />
        <h1>Thank you for your message!</h1>
        <p>I'll get back to you as soon as possible.</p>
        <Link to="/" className="btn btn-primary">
          <BsCaretLeftFill /> Go Back
        </Link>
      </ThankYouStyles>
    </Layout>
  );
};

export default ThankYou;
